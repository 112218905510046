<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.workteam.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.workteam.back' | translate}}</button>
                </div>
                <div class="col-md-3 offset-md-6">
                    <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                </div>

            </div>

        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.workteam.new' | translate">
                    <form @submit.prevent="onSubmit" autocomplete="off">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.workteam.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">
                                                <div class="input-group">
                                                    <input id="email" name="email" v-model="email" v-validate="'required|email'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="email">{{'view.workteam.fields.email' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('email')" class="help text-danger">
                                                        {{ errors.first('email') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('roles_teams')}">
                                                <div class="input-group">
                                                    <multiselect
                                                        v-model="rol"
                                                        deselect-label="Rol seleccionado"
                                                        label="name"
                                                        placeholder="Selecciona un Rol"
                                                        :options="rolesTeams"
                                                        :searchable="false"
                                                        :allow-empty="false">
                                                        <template slot="singleLabel" slot-scope="{ option }">
                                                          {{'view.workteam.fields.roles_teams' | translate}}
                                                          <label style="font-weight:600">{{ option.name }}</label>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('password')}">
                                                <div class="input-group">
                                                    <input type="password" id="password" name="password" v-model="password" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="email">{{'view.workteam.fields.password' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('password')" class="help text-danger">
                                                        {{ errors.first('password') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('password_confirmation')}">
                                                <div class="input-group">
                                                    <input type="password" id="password_confirmation" name="password_confirmation" v-model="password_confirmation" v-validate="'required|confirmed:password'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="email">{{'view.workteam.fields.password_confirmation' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('password_confirmation')" class="help text-danger">
                                                        {{ errors.first('password_confirmation') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.workteam.save')}}</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'New_User',
  components: {
    Multiselect
  },
  data () {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      rol: '',
      rolesTeams: [],
    }
  },
  mounted () {
    let params={
      active:1
    }
    this.loadRolesWorkTeam(params)
      .then((data) => {
        this.rolesTeams = data
      })
      .catch(data => {
        this.error = data.message
      })
  },
  methods: {
    ...mapActions([
      'loadRolesWorkTeam',
      'storeWorkteam',
      'addToastMessage',
    ]),
    onSubmit () {

      if(this.rol == ""){
        this.addToastMessage({
                text: 'Indica un Rol',
                type: 'warning'
              })
      }else{
        this.$validator.validate().then(result => {
        if (result) {
          let form_submit ={
            name: this.name,
            email: this.email,
            id_default_rol: this.rol.id,
            id_company: this.company.id,
            password: this.password,
            password_confirmation: this.password_confirmation
          }
          this.storeWorkteam(form_submit)
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              this.$router.replace('/workteam')
            })
            .catch((data) => {
              this.error = data.message
            })
        }
      })
      }
     
    },
    clear (field) {
      this[field] = ''
    },
    back () {
      this.$router.go(-1)
      // this.$router.push('/')
    },
  },
  computed: {
    ...mapState({
      company: state => state.auth.company
    }),
    isSuccessNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid = this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    },
  },
}
</script>
